<template>
  <main class="bg-gray-100 h-full">
    <navigation-user-sidebar>
      <popups-notification class="-mt-10 -mx-4 sm:-mx-6 lg:-mx-8"/>

      <slot/>
    </navigation-user-sidebar>
  </main>
</template>
<script setup lang="ts">
</script>