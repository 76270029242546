<template>
  <div>
    <div v-if="showMobileSidebarMenu" class="relative z-50 lg:hidden">
      <div class="fixed inset-0 bg-gray-900/80"></div>

      <div class="fixed inset-0 flex">
        <div class="relative mr-16 flex w-full max-w-xs flex-1">
          <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
            <button class="-m-2.5 p-2.5" @click="showMobileSidebarMenu = false">
              <icon class="text-white" name="material-symbols:close-small-rounded"/>
            </button>
          </div>

          <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 ring-1 ring-white/10">
            <nav class="flex flex-1 flex-col mt-4">
              <ul class="flex flex-1 flex-col gap-y-7" role="list">
                <li>
                  <ul class="-mx-2 space-y-1">
                    <template v-for="menuItem in menuItems">
                      <li :class="{'hidden': menuItem.href === '/automations' && user.plan !== 'Enterprise'}">
                        <nuxt-link
                            :class="$route.path === menuItem.href ? 'bg-gray-800 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800'"
                            :to="menuItem.href"
                            class="bg-gray-800 text-white group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold">
                          <icon :name="menuItem.icon" class="shrink-0"/>

                          {{ menuItem.name }}
                        </nuxt-link>
                      </li>
                    </template>
                  </ul>
                </li>

                <li>
                  <div class="text-xs font-semibold leading-6 text-gray-400">
                    <!-- Don't miss out -->
                  </div>

                  <ul class="-mx-2 mt-2 space-y-1">
                    <li v-for="extraMenuItem in extraMenuItems">
                      <nuxt-link
                          :to="extraMenuItem.href"
                          class="text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          target="_blank">
                        <span
                            class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                          {{ extraMenuItem.name[0] }}
                        </span>

                        <span>
                          {{ extraMenuItem.name }}
                        </span>
                      </nuxt-link>
                    </li>
                  </ul>
                </li>

                <li class="mt-auto flex">
                  <a :href="`mailto:support@postpolish.com`" class="btn-outline-white-xs w-full">
                    Support
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
      <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4">
        <div class="flex h-16 shrink-0 justify-center items-center bg-white -mx-6 border-r">
          <nuxt-link to="/import">
            <img class="h-8 w-auto" src="/logo.png"/>
          </nuxt-link>
        </div>
        <nav class="flex flex-1 flex-col">
          <ul class="flex flex-1 flex-col gap-y-7" role="list">
            <li>
              <ul class="-mx-2 space-y-1">
                <template v-for="menuItem in menuItems">
                  <li :class="{'hidden': menuItem.href === '/automations' && user.plan !== 'Enterprise'}">
                    <nuxt-link
                        :class="$route.path === menuItem.href ? 'bg-gray-800 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800'"
                        :to="menuItem.href"
                        class="group flex items-center gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold">
                      <icon :name="menuItem.icon" class="shrink-0"/>

                      {{ menuItem.name }}
                    </nuxt-link>
                  </li>
                </template>
              </ul>
            </li>

            <li>
              <div class="text-xs font-semibold leading-6 text-gray-400">
                Don't miss out
              </div>

              <ul class="-mx-2 mt-2 space-y-1" role="list">
                <li v-for="extraMenuitem in extraMenuItems">
                  <a :href="extraMenuitem.href"
                     class="text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                     target="_blank">
                    <span
                        class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                      {{ extraMenuitem.name[0] }}
                    </span>

                    <span>
                      {{ extraMenuitem.name }}
                    </span>
                  </a>
                </li>

                <li v-if="appType && user.plan === 'Enterprise'"
                    class="text-white border border-white p-2 text-sm text-center cursor-pointer hover:bg-gray-100 hover:text-gray-900" @click="switchApp">
                  Switch to {{ appType === 'postlytic' ? 'PostPolish' : 'Postlytic' }}
                </li>
              </ul>
            </li>

            <li class="mt-auto flex">
              <a :href="`mailto:support@postpolish.com`" class="btn-outline-white-xs w-full">
                Support
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div class="lg:pl-72">
      <div
          class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
        <div class="flex flex-1 gap-x-4 self-stretch items-center justify-between lg:justify-end lg:gap-x-6">
          <div class="hidden lg:flex items-center gap-x-4 lg:gap-x-6">
            <div class="select-none text-sm font-semibold leading-6 text-gray-900">
              {{ user.timezone }} {{ currentTime ?? '' }}
            </div>

            <div class="relative">
              <button id="profile_dropdown" class="-m-1.5 flex items-center p-1.5"
                      @click="showProfileMenu = !showProfileMenu">
                <div class="hidden lg:flex lg:items-center text-sm font-semibold leading-6 text-gray-900">
                  <span aria-hidden="true" class="ml-4">
                    {{ user.name }}
                  </span>

                  <icon class="ml-2 text-gray-400" name="mdi:chevron-down"/>
                </div>
              </button>

              <div v-if="showProfileMenu"
                   class="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
                   tabindex="-1">
                <nuxt-link :class="{'bg-gray-50': $route.path === '/profile'}"
                           class="block px-3 py-1 text-sm leading-6 text-gray-900" tabindex="-1"
                           to="/profile">
                  Your profile
                </nuxt-link>

                <nuxt-link :class="{'bg-gray-50': $route.path === '/billing'}"
                           class="block px-3 py-1 text-sm leading-6 text-gray-900" tabindex="-1"
                           to="/billing">
                  Billing
                </nuxt-link>

                <a class="block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer" tabindex="-1"
                   @click.prevent="logout">
                  Sign out
                </a>
              </div>
            </div>
          </div>

          <nuxt-link class="lg:hidden" to="/import">
            <img class="h-6 w-auto" src="/logo.png"/>
          </nuxt-link>

          <button class="-m-2.5 p-2.5 text-gray-700 lg:hidden" @click="showMobileSidebarMenu = true">
            <icon name="mdi:hamburger"/>
          </button>
        </div>
      </div>

      <main class="py-10">
        <div class="px-4 sm:px-6 lg:px-8">
          <slot/>
        </div>
      </main>
    </div>
  </div>
</template>

<script setup>
import {useAuthStore} from '~/stores/auth.js'
import {addClosable, closeClosable} from "~/helpers/closable.js"

const router = useRouter()

const showProfileMenu = ref(false)
const showMobileSidebarMenu = ref(false)

const currentTime = ref(new Date().toLocaleTimeString('en-US', {
  timeZone: useAuthStore().user.timezone,
  hour: '2-digit',
  minute: '2-digit',
}))

let timer

const updateTime = () => {
  currentTime.value = new Date().toLocaleTimeString('en-US', {
    timeZone: useAuthStore().user.timezone,
    hour: '2-digit',
    minute: '2-digit',
  })
}

const menuItems = ref([])

const appType = ref()

onMounted(() => {
  const isScheduler = localStorage.getItem('is_scheduler')

  appType.value = isScheduler ? 'postlytic' : 'postpolish'

  if (isScheduler) {
    menuItems.value.push({name: 'Create', href: '/create', icon: 'material-symbols:add-circle-rounded'})
    menuItems.value.push({name: 'Posts', href: '/posts', icon: 'f7:layers-alt-fill'})
    menuItems.value.push({name: 'Connect', href: '/connect/all', icon: 'fluent:pipeline-20-filled'})
    menuItems.value.push({name: 'RSS', href: '/rss', icon: 'ic:baseline-rss-feed'})
    menuItems.value.push({name: 'Scheduler', href: '/scheduler', icon: 'material-symbols:edit-calendar'})
  } else if (!isScheduler) {
    menuItems.value.push({name: 'Import', href: '/import', icon: 'material-symbols:assignment-add-rounded'})
    menuItems.value.push({name: 'Links', href: '/links', icon: 'material-symbols:link-rounded'})
    menuItems.value.push({name: 'RSS Feeds', href: '/feeds', icon: 'material-symbols:rss-feed-rounded'})
    menuItems.value.push({name: 'Automations', href: '/automations', icon: 'octicon:feed-merged-16'})
    menuItems.value.push({
      name: 'Verified Domains',
      href: '/domains',
      icon: 'material-symbols:domain-verification-rounded'
    })
  }

  menuItems.value.push({name: 'Billing', href: '/billing', icon: 'ic:round-payments'})
  menuItems.value.push({name: 'Profile', href: '/profile', icon: 'material-symbols:manage-accounts-rounded'})

  addClosable(closeProfileMenu)
  timer = setInterval(updateTime, 1000)
})

onUnmounted(() => {
  closeClosable(closeProfileMenu)

  if (timer) clearInterval(timer)
})

function closeProfileMenu(e) {
  const dropdownEl = document.getElementById('profile_dropdown')

  if (dropdownEl && !dropdownEl.contains(e.target)) {
    showProfileMenu.value = false
  }
}

const user = computed(() => useAuthStore().user)

const extraMenuItems = ref([
  {name: 'Help Center', href: 'https://docs.postpolish.com'},
])

function logout() {
  useApi('auth/logout', {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + useCookie('token').value
    },
  }).then(async () => {
    await router.push('/')

    window.location.reload()
  })
}

function switchApp() {
  if (appType.value === 'postlytic') {
    localStorage.removeItem('is_scheduler')
  } else {
    localStorage.setItem('is_scheduler', true)
  }

  window.location.reload()
}
</script>
